import Swiper from 'swiper';
import { Navigation, Thumbs, Pagination, EffectFade } from 'swiper/modules';

export class SwiperComponent {
    swiperElement: Swiper;
    swiperThumbsElement: Swiper;

    private readonly OPTIONS = {
        loopValue: true,
        grabCursorValue: true,
        spaceBetween: 32,
        autoHeight: false,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        paginationClickable: true
    } as const;

    constructor() {
        this.productGalleryInit();
    }

    private productGalleryInit() {
        this.swiperThumbsElement = new Swiper('[data-a-gallery-thumbs]', {
            slidesPerView: this.OPTIONS.slidesPerView,
            freeMode: this.OPTIONS.freeMode,
            watchSlidesProgress: this.OPTIONS.watchSlidesProgress,
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    spaceBetween: 8
                },
                480: {
                    slidesPerView: 5,
                    spaceBetween: 8
                },
                620: {
                    slidesPerView: 3,
                    spaceBetween: 8
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 16
                }
            },
        })

        this.swiperElement = new Swiper('[data-a-gallery]', {
            modules: [Thumbs, Navigation, Pagination, EffectFade],
            loop: this.OPTIONS.loopValue,
            autoHeight: this.OPTIONS.autoHeight,
            spaceBetween: this.OPTIONS.spaceBetween,
            grabCursor: this.OPTIONS.grabCursorValue,
            navigation: {
                prevEl: '[data-a-gallery-button-prev]',
                nextEl: '[data-a-gallery-button-next]',
            },
            pagination: {
                el: '[data-a-gallery-pagination]',
                clickable: this.OPTIONS.paginationClickable,
                bulletClass: 'gallery-bullet',
                bulletActiveClass: 'is-active',
            },
            fadeEffect: { crossFade: true },
            thumbs: {
                swiper: this.swiperThumbsElement,
            },
        });
    }
}
