'use strict';

import 'htmx.org';

import { AlpineJSComponent } from './components/alpineJs.component';
new AlpineJSComponent();

import { NavigationComponent } from './components/navigation.component';
new NavigationComponent();

import { IntegerComponent } from './components/integer.component';
new IntegerComponent();

import { SwiperComponent } from './components/swiper.component';
new SwiperComponent();

/**
 * SCSS import
 * DO NOT REMOVE !!
 */

import '../scss/main.scss';
