/**
 * How to use:
 * Add a div with attribute "data-a-integer" around your input-integer element
 * Example:
 *  <div class="integer" data-a-integer>
 *      <input id="integerInputName" class="integer-input" data-a-integer-input
 *          type="number" name="number" min="0" max="100" required>
 *      <div class="integer-input-controls">
 *          <button class="integer-input-decrease" data-a-integer-decrease type="button">-</button>
 *          <button class="integer-input-increase" data-a-integer-increase type="button">+</button>
 *      </div>
 *  </div>
 */

export class IntegerComponent {
    private integerElements: NodeListOf<HTMLElement>; // data-a-integer
    private inputElement: HTMLInputElement; // data-a-integer-input
    private decreaseButtonElement: HTMLButtonElement; // data-a-integer-increase
    private increaseButtonElement: HTMLButtonElement; // data-a-integer-decrease

    constructor() {
        this.integerElements = document.querySelectorAll('[data-a-integer]');

        if (!this.integerElements) return;

        this.integerElements.forEach(element => {
            this.inputElement = element.querySelector('[data-a-integer-input]') as HTMLInputElement;
            this.decreaseButtonElement = element.querySelector('[data-a-integer-decrease]') as HTMLButtonElement;
            this.increaseButtonElement = element.querySelector('[data-a-integer-increase]') as HTMLButtonElement;

            if (
                this.decreaseButtonElement
                && this.increaseButtonElement
                && this.inputElement
            ) {
                this.decrementItem(this.decreaseButtonElement, this.inputElement);
                this.incrementItem(this.increaseButtonElement, this.inputElement);
            }
        })
    }

    private decrementItem(buttonElement: HTMLButtonElement, inputElement: HTMLInputElement) {
        buttonElement.addEventListener('click', (e) => {
            e.preventDefault();

            const currentValue = Number(inputElement.value) || 0;

            if (currentValue > Number(inputElement.min)) {
                inputElement.value = (currentValue - 1).toString();

                this.updateButtonStates(inputElement);
            }
        })
    }

    private incrementItem(buttonElement: HTMLButtonElement, inputElement: HTMLInputElement) {
        buttonElement.addEventListener('click', (e) => {
            e.preventDefault();

            const currentValue = Number(inputElement.value) || 0;

            if (currentValue < Number(inputElement.max) || Number(inputElement.max) === 0 ) {
                inputElement.value = (currentValue + 1).toString();

                this.updateButtonStates(inputElement);
            }
        })
    }

    private updateButtonStates(inputElement: HTMLInputElement) {
        const currentValue = Number(inputElement.value) || 0;
        const min = Number(inputElement.min);
        const max = Number(inputElement.max);


        if (this.decreaseButtonElement) {
            if (currentValue <= min) {
                this.decreaseButtonElement.setAttribute('hidden', '');
            } else {
                this.decreaseButtonElement.removeAttribute('hidden');
            }
        }

        if (this.increaseButtonElement && max > 0) {
            if (currentValue >= max) {
                this.increaseButtonElement.setAttribute('disabled', '');
            } else {
                this.increaseButtonElement.removeAttribute('disabled');
            }
        }
    }
}